<template>
  <div id="app">
    <nav class="navbar navbar-dark bg-dark">
      <div class="container-fluid d-flex">
        <span class="navbar-brand h1">KOReader Note JSON to Markdown </span>
        <div class="d-flex">
          <a
            class="btn btn-sm btn-outline-light mx-2"
            href="https://gitlab.com/uroybd/koreader-to-markdown"
            ><FontAwesomeIcon icon="fa-brands fa-gitlab" />
            Source Code
          </a>
          <button
            class="btn btn-sm btn-outline-light mx-2"
            @click.prevent="openSettingsModal()"
          >
            <FontAwesomeIcon icon="fa fa-cog" />
          </button>
        </div>
      </div>
    </nav>
    <div class="container-fluid pt-3">
      <div class="row">
        <div class="col-lg-6 offset-lg-3">
          <label for="basic-url" class="form-label h5"
            >KOReader Exported JSON File (Must be valid)</label
          >
          <div class="input-group mb-3">
            <input
              type="file"
              class="form-control"
              id="inputFile"
              ref="inputfile"
              @change="handleInput"
              accept="application/json"
              placeholder="Export File (JSON)"
            />
            <button class="btn btn-danger" @click="clearInput" v-if="input && Object.keys(input).length != 0">
              Clear
            </button>
          </div>
        </div>
      </div>
      <div class="row main-container pt-3">
        <div class="col-lg-6 section input">
          <!-- <vue-json-pretty v-if="input" :data="input"> </vue-json-pretty> -->
          <vue-json-editor
            v-model="input"
            :mode="JSONEditorOptions.mode"
            :modes="JSONEditorOptions.modes"
            class="editor"
          ></vue-json-editor>
        </div>
        <div class="col-lg-6 section output">
          <div class="floating-menu" v-if="output">
            <button
              type="button"
              class="btn btn-sm btn-outline-dark"
              @click.prevent="isMarkdownView = !isMarkdownView"
            >
              <FontAwesomeIcon icon="fa-solid fa-eye" v-if="isMarkdownView" />
              <FontAwesomeIcon v-else icon="fa-brands fa-markdown" />
            </button>
            <button
              class="btn btn-sm btn-outline-secondary ml-2"
              @click="copyMD"
              id="copyBtn"
            >
              <FontAwesomeIcon icon="fa-solid fa-clipboard" /> Copy
            </button>
            <a
              v-if="filename && outputFile"
              class="btn btn-sm btn-outline-danger"
              :download="filename"
              :href="outputFile"
            >
              <FontAwesomeIcon icon="fa-solid fa-file-download" />
              Download
            </a>
          </div>
          <pre class="viewer" v-if="isMarkdownView">{{ output }}</pre>
          <VueMarkdown
            v-else
            id="text"
            class="viewer"
            ref="output"
            :watches="['output']"
            :source="output"
          >
          </VueMarkdown>
        </div>
      </div>
    </div>
    <div class="position-fixed toast-container end-0 p-3" style="z-index: 11">
      <div
        id="copyToast"
        class="toast bg-primary text-white hide"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div class="d-flex">
          <div class="toast-body">Text Copied!</div>
          <button
            type="button"
            class="btn-close btn-close-white me-2 m-auto"
            data-bs-dismiss="toast"
            aria-label="Close"
          ></button>
        </div>
      </div>
    </div>
    <div
      class="container-fluid settings-form p-5"
      v-if="settingsModal && settingsForm"
    >
      <h2>Settings</h2>
      <form class="container-fluid">
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            id="enableHighlight"
            v-model="settingsForm.enableHighlight"
          />
          <label class="form-check-label" for="enableHighlight"
            >Enable Highlight Styling</label
          >
        </div>
        <div class="row my-3">
          <div class="form-group col-lg-3 col-md-6">
            <label for="inputState">Lighten</label>
            <select
              id="inputState"
              class="form-control"
              v-model="settingsForm.lighten"
            >
              <option
                v-for="(val, idx) in highlightStyles"
                :key="`lighten-${idx}`"
                :value="val.value"
              >
                {{ val.label }}
              </option>
            </select>
          </div>
          <div class="form-group col-lg-3 col-md-6">
            <label for="inputState">Underscore</label>
            <select
              id="inputState"
              class="form-control"
              v-model="settingsForm.underscore"
            >
              <option
                v-for="(val, idx) in highlightStyles"
                :key="`underscore-${idx}`"
                :value="val.value"
              >
                {{ val.label }}
              </option>
            </select>
          </div>
          <div class="form-group col-lg-3 col-md-6">
            <label for="inputState">Strikeout</label>
            <select
              id="inputState"
              class="form-control"
              v-model="settingsForm.strikeout"
            >
              <option
                v-for="(val, idx) in highlightStyles"
                :key="`strikeout-${idx}`"
                :value="val.value"
              >
                {{ val.label }}
              </option>
            </select>
          </div>
          <div class="form-group col-lg-3 col-md-6">
            <label for="inputState">Invert</label>
            <select
              id="inputState"
              class="form-control"
              v-model="settingsForm.invert"
            >
              <option
                v-for="(val, idx) in highlightStyles"
                :key="`invert-${idx}`"
                :value="val.value"
              >
                {{ val.label }}
              </option>
            </select>
          </div>
        </div>
        <button type="submit" @click.prevent="saveSettings()" class="btn btn-primary">Submit</button>
        <button type="cancel" @click.prevent="closeSettingsModal()" class="btn btn-danger mx-2">Cancel</button>
      </form>
    </div>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown";
import "bootstrap/dist/css/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faGitlab, faMarkdown } from "@fortawesome/free-brands-svg-icons";
import {
  faClipboard,
  faEye,
  faFileDownload,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
// eslint-disable-next-line no-unused-vars
import Toast from "bootstrap/js/src/toast";
import dayjs from "dayjs";
import VueJsonEditor from 'vue-json-editor';

library.add(faGitlab, faFileDownload, faClipboard, faMarkdown, faEye, faCog);

const PUNCTUATIONS_REGEX = /([.?;!,——])([^\s])/g;

function adjustPunctuations(match, p1, p2) {
  return `${p1} ${p2}`;
}

const formatters = {
  plain: (text) => text,
  italic: (text) => `_${text}_`,
  bold: (text) => `**${text}**`,
  strikethrough: (text) => `~~${text}~~`,
  underline: (text) => `++${text}++`,
  boldItalic: (text) => `**_${text}_**`,
  underline_tag: (text) => `<u>[${text}]</u>`,
};

export default {
  name: "App",
  components: {
    VueMarkdown,
    FontAwesomeIcon,
    // VueJsonPretty,
    VueJsonEditor,
    // JSONEditor,
  },
  data() {
    return {
      input: {},
      output: "",
      filename: "",
      outputFile: "",
      isMarkdownView: false,
      copyToast: null,
      JSONEditorOptions: {
        mode: "code",
        modes: ["code", "view"],
      },
      highlightStyles: [
        { label: "Plain", value: "plain" },
        { label: "Italic", value: "italic" },
        { label: "Bold", value: "bold" },
        { label: "Strikethrough", value: "strikethrough" },
        { label: "Underline (using ++)", value: "underline" },
        { label: "Bold Italic", value: "boldItalic" },
        { label: "Underline Tag (using <u></u>)", value: "underline_tag" },
      ],
      settings: {
        enableHighlight: true,
        lighten: "italic",
        strikeout: "strikethrough",
        invert: "bold",
        underscore: "underline",
      },
      settingsForm: null,
      settingsModal: false,
    };
  },
  watch: {
    input: function (newInput, oldInput) {
      if (newInput != oldInput) {
        if (newInput.length == 0) {
          this.output = {};
        } else {
          const inputData = JSON.parse(JSON.stringify(newInput));
          const title = this.simplify(inputData.title);
          const author = this.simplify(inputData.author);
          const output = [`# ${title}`, `##### ${author}`, "\n"];
          let isModern = false;
          let last_header = "";
          let entries = [];
          if (inputData.entries == undefined) {
            let counter = 1;
            while (counter > -1) {
              let entry = inputData[`${counter}`];
              if (!entry) {
                break;
              }
              entries.push(entry[0]);
              counter++;
            }
          } else {
            entries = inputData.entries;
            isModern = true;
          }
          for (let entry of entries) {
            const header = this.simplify(entry.chapter);
            if (header != last_header) {
              output.push("\n");
              output.push(`## ${header}`);
              last_header = header;
            }
            const time = dayjs.unix(entry.time);
            output.push("\n");
            output.push(`### ${time.format("DD MMM YYYY hh:mm A")}`);
            let content = this.simplify(entry.text);
            if (isModern && this.settings.enableHighlight) {
              content = formatters[this.settings[entry.drawer]](content);
            }
            output.push(content);
            if (entry.note) {
              output.push("\n---");
              output.push(`*${entry.note}*`);
              output.push("\n");
            }
          }
          this.output = output.join("\n");
          this.filename = `${title} by ${author}.md`;
          var f = new Blob([this.output], {
            type: "text/plain",
          });
          this.outputFile = window.URL.createObjectURL(f);
        }
      }
    },
  },
  methods: {
    clearInput() {
      this.$refs["inputfile"].value = "";
      this.input = {};
    },
    copyMD() {
      navigator.clipboard.writeText(this.output);
      if (this.copyToast) {
        this.copyToast.show();
      }
    },
    simplify(text) {
      return text
        .replace("\n", " ")
        .replace(/\s+/g, " ")
        .replace(PUNCTUATIONS_REGEX, adjustPunctuations)
        .trim();
    },
    handleInput(evt) {
      const file = evt.target.files[0];
      if (file) {
        var fr = new FileReader();
        fr.onload = () => {
          this.input = JSON.parse(fr.result);
        };

        fr.readAsText(file);
      }
    },
    getSettingsFromBrowser() {
      const settingsStore = localStorage.getItem("settings");
      if (settingsStore) {
        this.settings = JSON.parse(settingsStore);
      } else {
        this.settings = {
          enableHighlight: true,
          lighten: "italic",
          strikeout: "strikethrough",
          invert: "bold",
          underscore: "underline",
        };
      }
    },
    createSettingsForm() {
      this.settingsForm = JSON.parse(JSON.stringify(this.settings));
    },

    openSettingsModal() {
      this.createSettingsForm();
      this.settingsModal = true;
    },

    closeSettingsModal() {
      this.settingsModal = false;
      this.settingsForm = null;
    },

    saveSettings() {
      this.settings = this.settingsForm;
      localStorage.setItem("settings", JSON.stringify(this.settings));
      this.settingsModal = false;
      this.settingsForm = null;
    },
  },
  mounted() {
    this.getSettingsFromBrowser();
    this.$nextTick(() => {
      this.copyToast = new Toast(document.getElementById("copyToast"), {
        autohide: true,
      });
    });
  },
};
</script>

<style lang="scss">
.section {
  height: 80vh;

  @media (max-width: 991.98px) {
    height: 40vh;
  }
}

.input {
  overflow-y: scroll;
}

.output {
  position: relative;
  padding-top: 50px;

  .floating-menu {
    position: absolute;
    top: 0;
    right: 12px;

    .btn {
      margin-left: 10px;
    }
  }

  .viewer {
    overflow-y: scroll;
    height: 100%;
  }

  pre {
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }
}

.toast-container {
  top: 5vh;
}

.editor {
  width: 100%;
  height: 100%;

  .jsoneditor-vue {
    height: 100%;
  }

  .jsoneditor {
    border-color: white;
  }
  .jsoneditor-menu {
    background-color: #202020;
    border-bottom: 1px solid #202020;
    color: #202020;

    button {
      color: #white;
    }
  }
}

.settings-form {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  background-color: white;
  border: 1px solid lightgray;
}
</style>
